import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.css';
import Home from './Home';
import Error from "./Error";


function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route component={Error}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
