import field from "./video/field.mp4";
import React from "react";

export default function Home() {
    return (
        <>
            <video autoPlay loop muted playsInline
                   style={{
                       position: 'absolute',
                       width: '100%',
                       left: '50%',
                       top: '50%',
                       height: '100%',
                       objectFit: 'cover',
                       transform: 'translate(-50%, -50%)',
                       zIndex: -1
                   }}
                   src={field}>
            </video>
            <h1 className='Comfortaa Title'>Ben van Rooyen</h1>
            <a className='emailLink' href="mailto:email@benvanrooyen.com">
                email@benvanrooyen.com
            </a>
        </>
    )
}