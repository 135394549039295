import React from "react";
import {Link} from "react-router-dom";

function Error() {

    return (
        <>
            <h1 className='Comfortaa Title'>Ben van Rooyen</h1>
            <Link to='/'>HOME</Link>
            <h3>Error 404</h3>
        </>
    )
}

export default Error;